
.drawer-definition-form {
  .sub-title {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .divider {
    height: 0;
    width: calc(100% + 70px);
    margin: 0 -35px;
    margin-bottom: 20px;
    border-bottom: 1px solid #D0D0D0;
  }
}
