
.page-setup .operational-metadata-management .fields-management {
  .category-fields-management:not(:last-child) {
    margin-bottom: 20px;

    .title {
      margin-left: 5px;

      font-size: 18px;
    }
  }

  .custom-button {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
}
