
.ticket-details {
  .ticket-details-title {
    margin: 10px;
    margin-bottom: 20px;

    font-size: large;
    font-weight: bold;
  }

  .ticket-details-message {
    margin: 10px;
    margin-bottom: 20px;
  }

  .add-ticket-comment {
    margin-top: 15px;
    margin-left: 10px;
    margin-bottom: 25px;
  }

  .delete-ticket-details {
    display: flex;
    flex-direction: row-reverse;
  }

  .comment-input {
    margin: 0 10px;
  }

  .comments {
    margin-left: 10px;

    .comment {
      margin-bottom: 20px;

      .comment-message {
        margin-top: 5px
      }
    }
  }
}
