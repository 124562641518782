
@import 'src/theme/color';

.page-customer-metrics .customer-metric-definitions .definition {
  .actions {
    visibility: hidden;
  }

  &:hover {
    .actions {
      visibility: visible;
    }
  }

  .header {
    height: 20px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chart {
    height: 100px;
    width: 100%;
  }

  .content {
    display: flex;

    .number {
      flex: 0 0 120px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      > :nth-child(2) {
        margin-bottom: 8px;
        margin-top: 5px;

        font-size: 30px;
        line-height: 30px;
        font-weight: 400;
      }

      > *:last-child {
        height: 20px;
        padding: 0 12px;
        padding-top: 2px;
        width: 100px;
  
        font-size: 12px;
        line-height: 18px;

        text-align: center;
        color: white;

        &.green {
          background-color: $green-6;
        }

        &.red {
          background-color: $red-6;
        }

        &.grey {
          background-color: $grey-6;
        }
      }
    }
  }
}
