
@import "src/theme/color";

.page-cockpit {
  .lists-header, .list {
    padding-left: 15px;
  
    display: flex;

    > * {
      flex: 0 0 120px;
    }

    > *:first-child {
      flex: 0 0 200px;
    }

    > .health-distribution, > .nps-distribution {
      flex: 0 0 240px;
    }

    > *:last-child {
      flex: 1;
    }
  }

  .lists-header {
    margin-top: 5px;
    padding-bottom: 10px;

    color: #909090;
  }

  .list {
    height: 45px;

    align-items: center;

    .noData {
      color: #d0d0d0;
    }

    .health-distribution, .nps-distribution {
      display: flex;

      > * {
        margin-right: 15px;
      }

      .score {
        flex: 0 0 50px;

        display: flex;
        justify-content: space-between;
      }

      .score.good {
        color: $color-health-good;
      }

      .score.medium {
        color: $color-health-medium;
      }

      .score.bad {
        color: $color-health-bad;
      }

      .bar {
        display: flex;
        align-items: center;

        > *:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        > *:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        > * {
          height: 10px;
        }

        .good {
          background-color: $color-health-good;
        }

        .medium {
          background-color: $color-health-medium;
        }

        .bad {
          background-color: $color-health-bad;
        }

        .no-data {
          background-color: #d9d9d9;
        }
      }
    }
  }

  .list:last-child {
    margin-bottom: 0;
  }

  .list:hover {
    background-color: #F0F0F0;
  }
}
