
.modal-survey-create-update {
  .choices.label {
    margin-bottom: 5px;
  }

  .deletion-circle {
    margin-right: 11px;
  }
}
