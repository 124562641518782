@import 'src/theme/color';

.drawer-notification {
  .title {
    height: 35px;
    border-bottom: 1px solid $color-nav-divider;
    font-weight: 500;
    font-size: large;
    margin-bottom: 10px;
  }
  >* {
    margin-bottom: 15px;
  }
}

