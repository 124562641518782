
// base colors
$red-1: #fff1f0;
$red-2: #ffccc7;
$red-3: #ffa39e;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-6: #f5222d;
$red-7: #cf1322;
$red-n: #d1605d;

$volcano-1: #fff2e8;
$volcano-2: #ffd8bf;
$volcano-3: #ffbb96;
$volcano-4: #ff9c6e;
$volcano-5: #ff7a45;
$volcano-6: #fa541c;
$volcano-7: #d4380d;
$volcano-n: #967662;

$pink-n: #f2a2a9;
$pink-n2: #a87d9f;

$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-3: #ffd591;
$orange-4: #ffc069;
$orange-5: #ffa940;
$orange-6: #fa8c16;
$orange-n: #e49344;

$gold-1: #fffbe6;
$gold-5: #ffc53d;
$gold-6: #faad14;
$gold-7: #d48806;

$yellow-1: #feffe6;
$yellow-5: #ffec3d;
$yellow-6: #fadb14;
$yellow-n: #e7ca60;

$lime-1: #fcffe6;

$green-1: #f6ffed;
$green-2: #d9f7be;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-n: #6b9f58;

$cyan-1: #e6fffb;
$cyan-6: #13c2c2;
$cyan-7: #08979c;
$cyan-8: #006d75;
$cyan-n: #85b6b2;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-n: #5777a3;

$geekblue-1: #f0f5ff;
$geekblue-4: #85a5ff;
$geekblue-5: #40a9ff;
$geekblue-6: #2f54eb;
$geekblue-7: #1d39c4;

$purple-1: #f9f0ff;
$purple-5: #9254de;
$purple-6: #722ed1;

$magenta-1: #fff0f6;
$magenta-6: #eb2f96;

$grey-1: #ffffff;
$grey-2: #fafafa;
$grey-3: #f5f5f5;
$grey-4: #f0f0f0;
$grey-5: #d9d9d9;
$grey-6: #bfbfbf;
$grey-7: #8c8c8c;
$grey-8: #595959;
$grey-9: #434343;
$grey-10: #262626;
$grey-n: #BAB0AD;

// theme colors
$color-primary: #233044;
$color-primary-highlighted: hsl(216, 32%, 30%);
$color-primary-border-highlight: hsl(216, 30%, 60%);

$color-nav-background: $color-primary;
$color-nav-divider: #E0E0E0;

// layout colors
$color-border: $grey-6;
$color-background: $grey-n;

// semantic colors
$color-failure: $red-5;
$color-success: $green-7;

// health
$color-health-good: $green-6;
$color-health-medium: $orange-4;
$color-health-bad: $red-5;

// trend
$color-increase: $cyan-6;
$color-decrease: $volcano-5;
$color-trend-up: $color-increase;
$color-trend-down: $color-decrease;

:export {
  healthGood: $color-health-good;
  healthMedium: $color-health-medium;
  healthBad: $color-health-bad;
  healthGoodFuture: $green-4;
  healthMediumFuture: $orange-2;
  healthBadFuture: $red-3;
  colorPrimary: $color-primary;
  trendUp: $color-trend-up;
  trendDown: $color-trend-down;
  success: $color-success;
  failure: $color-failure;
}
