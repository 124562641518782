
.health-score-panel {
  .health-model-category-panel {
    height: 150px;

    display: flex;
    align-items: stretch;
    
    > *:first-child {
      flex: 0 0 240px;
      margin-right: 40px;
    }

    >*:nth-child(2) {
      flex: 1;
      height: 150px;
    }

    .category-trends {
      height: 120px;
  
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > * {
        height: 50px;
        flex: 1 0 230px;
      }
    }

    .category-trend {
      width: 100%;

      display: flex;
  
      > *:first-child {
        width: 70px;
      }

      > div:nth-child(2) {
        padding: 10px 0;
        padding-right: 20px;
      }
    }
  }
}
