
$nav-height: 50px;

$box-shadow-card: 0 1px 10px lighten(#000, 85%);

@mixin card {
  //box-shadow: $box-shadow-card;
  margin-bottom: 30px;
}

@mixin page-button {
  width: 150px;
  height: 40px;
  margin-right: 20px;
}

@mixin table-button {
  width: 100px;
  margin-right: 15px;
}
