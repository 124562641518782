
.page-customers-health .dashboard .matrix .cell {
  padding-right: 50px;
  padding-bottom: 15px;
  padding-top: 40px;

  display: flex;

  .radar {
    flex: 1 1 300px;
  }

  .distribution-trend {
    flex: 1 1 300px;
  }
}
