
.page-cockpit .summary {
  .section-title {
    padding-left: 5px;
    margin-bottom: 15px;

    font-size: 18px;

    display: flex;

    > *:first-child {
      margin-right: 10px;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
}

.page-cockpit {
  .notifications-count {
    display: flex;
    
    > *:first-child {
      margin-right: 10px;
    }
  }

  .notifications-table {
    margin-top: 10px;
    margin-bottom: 12px;

    > *:first-child, > *:last-child > * {
      padding-left: 15px;

      display: flex;

      > *:first-child {
        flex: 0 0 35px;
      }

      > *:nth-child(2) {
        flex: 0 0 35px;
      }

      > *:nth-child(3) {
        flex: 0 0 300px;
      }
    }

    > *:first-child {
      margin-top: 20px;
      padding-bottom: 10px;
  
      color: #909090;
    }

    > *:last-child > * {
      height: 45px;

      align-items: center;
    }

    > *:last-child > *:hover {
      background-color: #F0F0F0;
    }
  }
}
