
.page-setup .operational-metadata-management .object-management {
  margin-bottom: 15px;
  padding-top: 10px;

  .title {
    margin-bottom: 15px;

    line-height: 36px;
    font-size: 36px;
  }

  .object-name {
    margin-bottom: 10px;
    margin-left: 5px;

    color: #B0B0B0;
  }

  .description {
    margin-left: 5px;
    margin-bottom: 15px;
  }
}
