
.modal-notifications-deletion {
  .title > div {
    margin-bottom: 15px;
    font-weight: bolder;
  }
  .message > div {
    margin-bottom: 15px;
  }
}
