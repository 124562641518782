
@import 'src/theme/color';

.page-customers-health .models .health-model-editor {
  height: 800px;
  margin-bottom: 5px;

  display: flex;
  flex-direction: column;

  .actions {
    margin-bottom: 15px;

    > * {
      width: 80px;
      margin-right: 15px;
    }
  }

  .editing-pane {
    flex: 1;

    display: flex;

    > * {
      flex: 1 1;
    }

    > .divider {
      flex: 0 0 0;
      height: 100%;
      margin: 0 20px;
      border-right: 1px solid #E0E0E0;
    }
  }
}
