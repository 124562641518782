
@import 'src/theme/color';

.breakdown-graph-chart .node {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px #C0C0C0 solid;

  display: flex;
  align-items: center;

  &.depth-0 {
    padding: 5px;
    padding-right: 15px;

    background-color: #F7FAFA;

    display: flex;
    justify-content: center;

    .text {
      font-size: 16px;
    }
  }

  &.depth-2 {
    border: none;
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  > *:nth-child(2) {
    margin: 0 5px;
  }

  .text {
    font-size: 11px;
  }

  .score {
    height: 100%;

    .circular-chart {
      height: 100%;
      margin: 0 5px;
      padding: 5px 0;
    }

    .circle-inner {
      fill: none;
      stroke: #D0D0D0;
      stroke-width: 3px;
    }

    .circle {
      fill: none;
      stroke-width: 3px;
      stroke-linecap: round;
    }
  }

  .weight {
    margin-top: 2px;

    color: #A0A0A0;
  }
}
