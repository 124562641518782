
@import 'src/theme/color';

.page-customers-dashboards .retention {
  .good {
    color: $color-health-good;
  }

  .medium {
    color: $color-health-medium;
  }

  .bad {
    color: $color-health-bad;
  }
}
