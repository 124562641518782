
.customer-basic-info .payment-history-panel {
  .timeline {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: -40px;
    width: 90%;
  }

  .event-row {
    display: flex;

    > *:nth-child(1) {
      flex: 0 0 80px;
    }

    > *:nth-child(2) {
      flex: 0 0 400px;
    }

    > *:nth-child(3) {
      flex: 1;

      color: #C0C0C0;
    }
  }

  .no-data {
    margin-left: 15px;
  }
}
