
@import 'src/theme/text';
@import 'src/theme/color';

html {
  box-sizing: border-box;

  font-family: $font-family;
  font-size: 14px;
}

body {
  overscroll-behavior: none;

  color: #606060;
  background: hsl(216, 35.5%, 97.5%);
}

#root {
  min-height: 100%;
}

#root {
  .g2-tooltip-list-item {
    width: 135px;
  }

  .ant-tabs {
    height: 100%;

    overflow: visible;

    .ant-tabs-content-holder {
      height: 100%;
    }

    .ant-tabs-content {
      height: 100%;
    }
  }
}

#root {
  .MuiPaper-root {
    box-sizing: border-box;
    overflow: visible;
    height: 100%;

    border-width: 2px;
    border-radius: 0;
    box-shadow: rbg(50, 50, 93 / 5%) 0px 2px 5px -1px, rbg(0 0 0 / 10%) 0px 1px 3px -1px;

    &:hover {
      border: 2px solid $color-primary-border-highlight;
    }
  }
}



#root {
  // container height fix
  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container {
    height: 100%;
  }

  .ant-table {
    thead th {
      border: none;
      padding: 0 16px;
      padding-bottom: 10px;
      padding-top: 10px;

      font-weight: 300;
      background: white;
    }

    tbody td {
      border: none;
      border-top: 0.5px solid #F0F0F0;
      padding: 10px 16px;
    }
  }
}

#root {
  .ant-timeline-item-label {
    text-align: left;
  }

  .ant-timeline-item-head {
    left: 100px;
  }

  .ant-timeline-item-tail {
    left: 100px;
  }

  .ant-timeline-item-content {
    left: 100px;
    width: 100%;
  }
}

#root a {
  color: $blue-8;
  text-decoration: underline;
  text-underline-offset: 2px;
}

#root .ant-pagination-item a {
  text-decoration: none;
  text-underline-offset: 0;
}

#root .MuiPagination-ul button {
  line-height: 12.25px;
}

#root .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}