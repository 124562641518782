
@import 'src/theme/color';

.drawer-custom-formula-form .if-else-formula-field {
  button {
    margin-right: 10px;
  }

  .condition-outcome {
    margin-bottom: 10px;
    padding: 5px 0;

    display: flex;

    > *:first-child {
      margin-right: 5px;
      margin-top: 5px;
      flex: 0 0 16px;

      line-height: 28.287px;
      font-size: 18px;
    }

    > *:nth-child(2) {
      flex: 1;
    }

    > .deletion-button {
      flex: 0 0 25px;
      padding-top: 10px;
      padding-left: 10px;

      > span {
        cursor: pointer;
      }
    }

    > .deletion-button.disabled {
      > span {
        color: #D0D0D0;
        cursor: not-allowed;
      }
    }
  }

  .condition, .outcome {
    height: 40px;

    display: flex;
    align-items: center;

    > *:first-child {
      flex: 0 0 50px;
      text-align: right;
    }

    .operator {
      * {
        line-height: 28px;
      }
    }
  }
}
