
@import 'src/theme/color';

.page-customer .factors {
  margin-top: 50px;

  .title {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .good {
    color: $color-health-good;
  }

  .medium {
    color: $color-health-medium;
  }

  .bad {
    color: $color-health-bad;
  }

  table {
    margin: 20px 0;
    width: 100%;
    text-align: center;
  }
}

.page-customer .factors {
  .child-node {
    padding-right: 5px;

    display: flex;

    .good {
      color: $color-health-good;
    }

    .medium {
      color: $color-health-medium;
    }

    .bad {
      color: $color-health-bad;
    }

    > * {
      flex: 0 0 40px;
      text-align: right;
    }

    > *:first-child {
      flex: 0 0 20px;
    }

    > *:nth-child(2) {
      flex: 1;
    }

    > *:nth-child(3) {
      flex: 0 0 150px;
    }

    > *:nth-child(4) {
      flex: 0 0 40px;
    }

    > *:nth-child(5) {
      flex: 0;
      margin: 0 5px;
    }

    > *:last-child {
      flex: 0;
    }
  }

  .divider {
    margin: 5px 0;
    height: 0;
    width: 100%;
    border: none;
    border-top: 1px solid #C0C0C0;
  }
}

.page-customer .factors {
  .condition-outcome {
    margin-bottom: 10px;
    padding: 5px 15px;

    display: flex;

    > *:first-child {
      margin-right: 5px;
      margin-top: 5px;

      font-size: 18px;
    }
  }

  .condition-outcome.true {
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #C0C0C0;
  }

  .condition, .outcome {
    height: 40px;

    display: flex;
    align-items: center;

    > *:first-child {
      flex: 0 0 50px;
      text-align: right;
    }
  }

  .expression {
    display: flex;
    align-items: center;

    > * {
      flex: 0 0 20px;

      text-align: center;
    }

    .metric-definition, .field-name {
      flex: 0 0 auto;
      margin: 0 5px;
      padding: 5px 10px;
      border: 1px solid #C0C0C0;

      background-color: #F0F0F0;
    }
  }

  .metrics {
    margin-top: 30px;
  }

  .metrics, .attributes {
    > *:first-child {
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .empty {
      color: #C0C0C0;
    }

    .metric, .attribute {
      display: flex;
    }
  }
}
