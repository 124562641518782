
.object-permissions {
  border: 1px solid #D0D0D0;
  padding: 20px;

  .title-row {
    display: flex;
    align-items: center;

    .title {
      margin: 0;
      margin-left: 15px;
    }
  }

  .divider {
    margin: 0 -20px;
    margin-top: 20px;
    height: 0;
    width: calc(100% + 40px);
    border-bottom: 1px solid #D0D0D0;
  }

  .section {
    margin-top: 10px;
    padding-top: 10px;

    button {
      margin-right: 15px;
    }
  }

  .create-delete {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 15px;
    }
  }

  .workflows-list {
    margin: 15px 0;

    display: flex;
    flex-wrap: wrap;

    .workflow-toggle {
      width: 200px;
      padding: 10px 0;

      display: flex;
      align-items: center;
      line-height: 14px;
      font-size: 12px;

      .ant-checkbox {
        top: 0;
        margin-right: 10px;
      }
    }
  }

  .columns-table {
    margin: 15px 0;
  }
}

#app .columns-table .ant-table-cell {
  font-size: 12px;
}

.object-permissions:not(:last-child) {
  margin-bottom: 20px;
}
