
.page-customer .customer-dashboard .left-panel {
  height: 100%;

  .title {
    height: 28px;
    margin-bottom: 5px;
  }

  .info {
    margin-bottom: 20px;

    > * {
      margin-bottom: 5px;

      display: flex;

      > *:first-child {
        flex: 0 0 100px;

        color: #A5A5A5;
      }
    }
  }
}
