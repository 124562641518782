
@import 'src/theme/color';

.roles-table {
  padding: 10px;
  padding-right: 30px;

  .title {
    margin-bottom: 20px;
  }

  .active {
    color: $green-6;
  }

  .inactive {
    color: $grey-6;
  }

  .actions button {
    margin-right: 15px;
  }
}
