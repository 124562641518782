
@import 'src/theme/color';

.dimension-table {
  height: 310px;
  padding-top: 5px;
  padding-bottom: 7px;
  border: 1px solid #D0D0D0;

  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .control-footer {
    padding-left: 10px;
    padding-right: 8px;
    height: 22px;

    display: flex;
    align-items: flex-start;

    > *:first-child {
      flex: 1;
    }

    > *:not(:first-child) {
      flex: 0 0 initial;
    }

    button {
      height: 22px;
      padding: 0 4px;
      line-height: 16px;
    }
  }

  .header-row {
    margin-bottom: 3px;
    border-bottom: 1px solid #D0D0D0;

    > *:not(:first-child) {
      height: 20px;
      line-height: 20px;
    }

    :hover {
      cursor: pointer;
    }
  }

  .row {
    padding-right: 10px;
    height: 30px;
    width: 100%;

    display: flex;
    align-items: center;
    
    > * {
      width: 60px;

      display: flex;
  
      > *:not(:last-child) {
        margin-right: 5px;
      }
    }

    > *:first-child {
      width: 40px;
      justify-content: center;
    }

    > *:nth-child(2) {
      flex: 1;
      text-align: left;
    }
  }

  .row:not(:first-child):hover {
    cursor: pointer;
    background-color: #F0F0F0;
  }

  .row.selected {
    color: white;
  }

  .row.deselected {
    color: #C0C0C0;
  }

  .row.selected.mode-SELECT, .row.selected.mode-select:hover {
    background-color: #005170; // TODO: primary color, antv daybreak blue
  }

  .row.selected.mode-EXCLUDE, .row.selected.mode-exclude:hover {
    background-color: #AA311C; // dusk red
  }

  .row.selected.mode-COMPARE.selection-0 {
    color: #4e79a7; // Tableau 10
  }
  .row.selected.mode-COMPARE.selection-1 {
    color: #f28e2c;
  }
  .row.selected.mode-COMPARE.selection-2 {
    color: #e15759;
  }
  .row.selected.mode-COMPARE.selection-3 {
    color: #76b7b2;
  }
  .row.selected.mode-COMPARE.selection-4 {
    color: #59a14f;
  }
  .row.selected.mode-COMPARE.selection-5 {
    color: #edc949;
  }
  .row.selected.mode-COMPARE.selection-6 {
    color: #af7aa1;
  }
  .row.selected.mode-COMPARE.selection-7 {
    color: #ff9da7;
  }
  .row.selected.mode-COMPARE.selection-8 {
    color: #9c755f;
  }
  .row.selected.mode-COMPARE.selection-9 {
    color: #bab0ab;
  }

  .percent-delta.positive {
    color: #389e0d;
  }

  .row.selected .percent-delta.positive {
    color: #95de64;
  }

  .row.deselected .percent-delta.positive {
    color: inherit;
  }

  .percent-delta.negative {
    color: red;
  }
}

#app .dimension-table .mode {
  span {
    font-size: 12px;
  }

  label {
    height: 22px;
    padding: 0 3px;
    margin: 0 3px;
    border: none;
    border-radius: 0;
  
    line-height: 22px;
    text-align: center;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: white;
  }

  .ant-radio-button-wrapper-disabled {
    background-color: white;
  }
  
  .ant-radio-button-wrapper-checked {
    border-bottom: 1px solid $color-primary;
  }
}


.dimension-table.maximized {
  height: 100%;
}

.sales-performance .dimension-table:hover {
  border: 1px solid $color-primary-highlighted;
}
