
.page-customer .customer-dashboard .nps-distribution-panel {
  .content {
    height: 100%;

    display: flex;
    flex-direction: column;

    .score {
      flex: 0 0 48px;
      margin-top: 15px;
  
      font-size: 48px;
      line-height: 48px;
    }

    .chart {
      margin-top: 15px;
      flex: 1;
    }
  }
}
