
.sales-performance .notifications {
  margin-bottom: 20px;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  button {
    border: none;
    padding: 0 10px;
    height: 20px;
  }
}
