
.page-setup .operational-metadata-management .object-selection {
  padding-top: 5px;

  .object-row {
    padding: 0 15px;
    height: 45px;

    line-height: 45px;
    cursor: pointer;

    &:hover, &.selected {
      background-color: #E9E9E9;
    }
  }

  .object-row:not(:last-child) {
    margin-bottom: 5px;
  }  
}
