
@import 'src/theme/layout';
@import 'src/theme/color';

.nav {
  position: fixed;
  width: 100%;
  min-width: 900px;
  height: $nav-height;
  z-index: 102;

  background-color: $color-nav-background;

  display: flex;
  align-items: center;

  .logo {
    margin-left: 25px;
    margin-right: 40px;
    height: 30px;

    img {
      height: 30px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .current-user {
    height: 100%;
    margin-left: 10px;
    margin-right: 15px;
    padding: 0 10px;

    cursor: pointer;

    display: flex;
    align-items: center;
  }
}

.nav-dropdown-menu {
  width: 170px;
  padding: 5px 0;

  .user-menu-item {
    padding: 5px 20px;

    cursor: pointer;

    display: flex;
    align-items: center;

    > *:first-child {
      width: 25px;
    }
  }

  .user-menu-item:hover {
    background-color: $grey-5;
  }
}
