
.metric-chart {
  width: 100%;
  height: 240px;

  margin-top: 10px;
  margin-bottom: 20px;

  .title {
    margin: 20px;
    margin-bottom: 10px;
  }

  .chart {
    padding-left: 20px;
    padding-right: 15px;
    height: 200px;
  }
}
