@import 'src/theme/color';

.page-feedback-management {
  .survey-analytics {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: -5px;
    padding-bottom: 15px;
  }

  .chart-row {
    width: 100%;
    display: flex;
    > * {
      flex: 1;
    }
    > *:nth-child(1) {
      flex: 2;
    }
  }

  .title-and-chart {
    margin-top: 10px;
    margin-right: 20px;
    padding: 5px;
    padding-left: 42px;

    .title-row {
      display: flex;
      padding-right: 15px;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
    }
  }
}
