@import 'src/theme/color';

.notifications {
  .header {
    height: 35px;
    border-bottom: 1px solid $color-nav-divider;
    font-weight: 300;
  }

  .zero-state {
    margin-left: 10px;
  }

  .notification {
    height: 45px;
    margin-bottom: -1px;

    > * {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .underlined-link {
      width: 50px;
      border-bottom: black;
      border-bottom-color: black;
    }

    .action-button, .action-button-2 {
      width: 22px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: space-around;

      cursor: pointer;
    }

    .action-button:hover, .action-button-2:hover {
      background-color: #E0E0E0;
    }

    .action-buttons {
      display: flex;
      justify-content: space-evenly;
      padding-right: 5px;
    }

    .action-button:hover {
      background-color: $grey-6;
    }

    .actions {
      visibility: hidden;
      margin-left: 20px;
      margin-right: -20px;
    }
    
    &:hover .actions {
      visibility: visible;
    }
  }

  .header, .notification {
    display: flex;
    align-items: center;

    > *:nth-child(1) {
      flex: 0 0 80px;
    }

    > *:nth-child(2) {
      flex: 0 0 160px;
    }

    > *:nth-child(3) {
      flex: 0 0 40px;
    }

    > *:nth-child(4), > *:nth-child(5) {
      flex: 0 0 40px;
    }

    > *:nth-child(6) {
      flex: 1;
    }

    > *:nth-child(7) {
      flex: 1;
    }

    > *:nth-child(8) {
      flex: 1;
    }

    > *:last-child {
      flex: 0 0 60px;
    }
  }

  .header.archived, .notification.archived {
    > *:first-child {
      flex: 0 0 40px;
    }
  }

  .notification:last-child {
    border-bottom: none;
  }

  .notification:hover, .notification.interacting {
    background-color: #FAFAFA;

    .actions {
      visibility: visible;
    }
  }

  .notification.read {
    color: $grey-7;
  }

  .notification.unread {
    color: black;
    font-weight: bolder;
  }

  .notifications-table {
    margin-bottom: 15px;
  }

  .customer-link, .ticket-link {
    a.link {
      text-decoration: underline;
      color: $blue-n;
    }
  }
}

.ant-btn-link {
  text-decoration: underline;
}
