
.modal-alert-rule-create-update {
  .choices.label {
    margin-bottom: 5px;
  }

  .sub-title {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .divider {
    height: 0;
    width: calc(100% + 70px);
    margin: 0 -35px;
    margin-bottom: 20px;
    border-bottom: 1px solid #D0D0D0;
  }

  .ant-input-number {
    width: 100%;
  }

  .template {
    margin-left: 52.5px;
  }

  .additionalReceivers {
    margin-left: 11px;
  }

  .add-condition-button {
    margin-left: 52.5px;
  }
}

.condition {
  .key {
    margin-left: 52.5px;
  }

  .ant-col-2 {
    > *:last-child {
      float: right;
    }
  }
}
